export const elements = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "About",
        items: [
            {
                name: "About DFIIE",
                path: "/about/dfiie"
            },
            {
                name: "Vision & Mission",
                path: "/about/vision&Mission"
            },
            {
                name: "DFIIE Objective",
                path: "/about/objective"
            }
            ,
            {
                name: "Board of Director",
                path: "/about/director"
            },
            {
                name: "Chairman Message",
                path: "/about/vcMessage"
            },

            {
                name: "Director Message",
                path: "/about/directormessage"
            },
            {
                name: "Executive Team",
                path: "/about/team"
            },
            {
                name: "Incubated Start-ups",
                path: "/about/startups"
            },
            {
                name: "Focus Areas",
                path: "/about/focusareas"
            },

        ]
    },
    {
        name: "Support",
        items: [
            {
                name: "Legal Support",
                path: "/support/legal"
            },
            {
                name: "Technical Support",
                path: "/support/technical"
            },
            {
                name: "Product Designing",
                path: "/support/product-design"
            },
            {
                name: "Prototype Development Support",
                path: "/support/prototype"
            },
            {
                name: "Funding Support",
                path: "/support/funding"
            },
            {
                name: "Networking Support",
                path: "/support/networking"
            },
            {
                name: "Mentoring Support",
                path: "/support/mentoring"
            },
            {
                name: "Market Connect",
                path: "/support/market"
            },
            {
                name: "IP Support",
                path: "/support/ip"
            },
            {
                name: "HR Support",
                path: "/support/hr"
            }
        ]
    },
    {
        name: "Events",
        path: '/events'

    },
    {
        name: "Useful Links",
        items: [
            {
                name: "Startup India Seed Fund Scheme",
                path: "https://seedfund.startupindia.gov.in/"
            },
            {
                name: "DPIIT Startup Recognition",
                path: "https://www.startupindia.gov.in/content/sih/en/startupgov/startup_recognition_page.html"
            },
            {
                name: "MSlnS",
                path: "https://www.msins.in/"
            },
            {
                name: "Startup India",
                path: "https://www.startupindia.gov.in/"
            },
            {
                name: "India Brand Equity Foundation",
                path: "https://www.ibef.org/"
            },
            {
                name: "MSME Incubation",
                path: "https://my.msme.gov.in/inc/"
            }
        ]
    },
    {
        name: "News & Announcement",
        path: '/news&announcement'
    },
    {
        name: "Contact",
        path: '/contact'
    },
    {
        name: "Gallery",
        path: '/gallery'
    }
]
