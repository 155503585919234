import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Homelayout from '../layout/Home.layout';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { Helmet } from 'react-helmet';
import Vcmessageforhome from '../components/Home/vc message';
import Partner from '../components/Home/Partners';
import GalleryHome from '../components/Home/galleryHome';
import NewsLetter from '../components/Home/newsletters';
import Event from '../components/Home/events';
import loadingImage from "../components/images/Spin.gif"; // Import your loading image here

const HomePage = () => {
  const [countUp, setCountUp] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/homevalues`);
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          console.error('Unexpected data format:', response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>DBATU Forum Of Innovation & Incubation Enterprise (DFIIE)</title>
      </Helmet>
      <ScrollTrigger onEnter={() => setCountUp(true)}>
        <div className='flex px-3 py-3 gap-3 flex-wrap items-center justify-center'>
          {loading ? (
            <img src={loadingImage} alt="Loading..." className="w-16 h-16" /> // Show loading image
          ) : (
            data.map((item) => (
              <div className='w-[320px] border text-white p-2 rounded shadow-inner shadow-blue-500 hover:shadow-lg' style={{ backgroundColor: '#033d8b' }} key={item.id}>
                <h4 className='text-center md:text-3xl text-xl font-semibold font-mono'>
                  {countUp && <CountUp start={0} end={item.number} delay={0} duration={3.5} />}+
                </h4>
                <h1 className='md:text-xl text-lg text-center font-semibold font-sans'>
                  {item.title}
                </h1>
              </div>
            ))
          )}
        </div>
      </ScrollTrigger>
      <Event />
      <Vcmessageforhome />
      <Partner />
      <NewsLetter />
      <GalleryHome />
    </div>
  );
};

export default Homelayout(HomePage);
