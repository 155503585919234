import React, { useState } from 'react';
import logo from './images/logo2.png';
import { FaBars } from 'react-icons/fa'; // Hamburger icon
import { NavbarSm } from './Navbar'; // Mobile Navbar
import { Link } from 'react-router-dom'; // For navigation

const Header = () => {
    const [navbarVisible, setNavbarVisible] = useState(false); // State to control navbar visibility

    return (
        <header>
            {/* Mobile Navbar */}
            <div className={`lg:hidden absolute bg-white z-20 h-screen w-screen transition-all ease-in-out duration-200 overflow-x-hidden ${navbarVisible ? 'translate-x-0' : '-translate-x-full'}`}>
                <NavbarSm onClose={() => setNavbarVisible(false)} setNavbarVisible={setNavbarVisible} />
            </div>

            {/* Main Header */}
            <div className='flex items-center lg:justify-center shadow justify-between gap-4 py-2 px-4'>
                <div className='flex items-center gap-8 md:gap-4'>
                    {/* Logo */}
                    <div>
                        <Link to="/">
                            <img src={logo} alt='Logo' className='md:h-20 sm:h-20 w-auto h-20' />
                        </Link>
                    </div>

                    {/* University Name */}
                    <div className='sm:flex flex-col hidden'>
                        <h1 className='lg:text-3xl md:text-xl text-lg font-semibold text-blue-800 textFamily'>
                            DBATU Forum of Innovation, Incubation & Enterprise (DFIIE)
                        </h1>
                        <h6 className='lg:text-xl text-sm font-semibold'>
                            Dr. Babasaheb Ambedkar Technological University (DBATU), Lonere, Maharashtra - 402103
                        </h6>
                    </div>
                </div>

                {/* Hamburger Button for Mobile */}
                <div className='lg:hidden block'>
                    <button onClick={() => setNavbarVisible(true)}>
                        <FaBars size={30} />
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
