import React from 'react'; // Import React
import { useParams } from 'react-router-dom'; // Import useParams to access route parameters
import FundingSupport from '../components/Support/FundingSupport'; // Import support components
import HRSupport from '../components/Support/HRSupport';
import IPSupport from '../components/Support/IPSupport';
import LegalSupport from '../components/Support/LegalSupport';
import MarketConnect from '../components/Support/MarketConnect';
import MentoringSupport from '../components/Support/MentoringSupport';
import NetworkingSupport from '../components/Support/NetworkingSupport';
import ProductDesigning from '../components/Support/ProductDesigning';
import PrototypeDevSupport from '../components/Support/PrototypeDevSupport';
import TechnicalSupport from '../components/Support/TechnicalSupport';
import MainLayout from '../layout/Main.layout'; // Import main layout component

const Support = () => {
    const params = useParams(); // Get route parameters

    return (
        <div>
            {/* Render the appropriate support component based on the route parameter 'info' */}
            {params.info === 'funding' && <FundingSupport />} {/* Funding support */}
            {params.info === 'hr' && <HRSupport />} {/* HR support */}
            {params.info === 'ip' && <IPSupport />} {/* IP support */}
            {params.info === 'legal' && <LegalSupport />} {/* Legal support */}
            {params.info === 'market' && <MarketConnect />} {/* Market connection support */}
            {params.info === 'mentoring' && <MentoringSupport />} {/* Mentoring support */}
            {params.info === 'networking' && <NetworkingSupport />} {/* Networking support */}
            {params.info === 'product-design' && <ProductDesigning />} {/* Product designing support */}
            {params.info === 'prototype' && <PrototypeDevSupport />} {/* Prototype development support */}
            {params.info === 'technical' && <TechnicalSupport />} {/* Technical support */}
        </div>
    );
};

// Export the Support component wrapped in the MainLayout HOC
export default MainLayout(Support);
