import React, { useState } from 'react'; // Import React and hooks
import { AiFillPhone } from 'react-icons/ai'; // Import phone icon
import { MdEmail } from 'react-icons/md'; // Import email icon
import { IoLocation } from 'react-icons/io5'; // Import location icon
import MainLayout from '../layout/Main.layout'; // Import the main layout component
import SkeletonImage from '../components/SkeletonImage'; // Import skeleton loader for images
import { Helmet } from 'react-helmet'; // Import Helmet for managing the document head
import contact from '../components/images/contactus.jpg'; // Import contact image
import axios from 'axios'; // Import axios for API calls

const Contact = () => {
  // State variables for form inputs and messages
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true); // State for image loading
  const [error, setError] = useState(""); // State for error messages
  const [success, setSuccess] = useState(""); // State for success messages

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      // Send form data to the server
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/contactus/`, {
        name,
        email,
        message,
      });
      // Clear form fields
      setName('');
      setEmail('');
      setMessage('');
      setSuccess('Your message has been sent successfully!'); // Success message
      setError(''); // Clear any previous error messages
    } catch (error) {
      console.error('Error sending message:', error); // Log error
      setError('Failed to send message. Please try again later.'); // Set error message
      setSuccess(''); // Clear any previous success messages
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact | DFIIE</title> {/* Set the document title */}
      </Helmet>
      <div>
        <img
          className={`w-full h-1/2 ${loading ? 'hidden' : 'block'}`}
          src={contact} // Contact image source
          alt=""
          onLoad={() => setLoading(false)} // Hide skeleton when image loads
          onError={() => setLoading(true)} // Show skeleton if image fails to load
        />

        {loading && <SkeletonImage height={'24rem'} width={'100%'} />}  {/* Show skeleton while loading */}

      </div>
      <div className='py-4 flex flex-col justify-center items-center w-full'>
        <div className='text-center'>
          <h1 className='sm:text-xl lg:text-2xl xl:text-4xl font-bold text-center inline-block py-2 tracking-widest border-b-4 border-blue-800'>CONTACT US</h1>
          <p className={`text-gray-600 sm:text-md xl:text-lg py-4 px-2`}>Feel free to contact us by submitting the form below, and we will get back to you as soon as possible.</p>
        </div>
        {success && <p className="text-green-600">{success}</p>} {/* Display success message */}
        {error && <p className="text-red-600">{error}</p>} {/* Display error message */}
        <div className='w-full flex justify-center py-6'>
          <form className={`lg:w-3/5 md:w-4/5 w-11/12 rounded px-4 py-6 border shadow-lg flex flex-col gap-4`} onSubmit={handleSubmit}>
            {/* Form for user inputs */}
            <div className='flex flex-col'>
              <label htmlFor="name" className='font-bold py-1'>Name</label>
              <input type="text" value={name} name="name" id="name" className='border px-4 py-2 rounded bg-gray-200 text-gray-800 font-semibold focus:outline-none' placeholder='Enter Your Name' required onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="email" className='font-bold py-1'>Email</label>
              <input type="email" value={email} name="email" id="email" className='border px-4 py-2 rounded bg-gray-200 text-gray-800 font-semibold focus:outline-none' placeholder='Enter Your Email' required onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="message" className='font-bold py-1'>Message</label>
              <textarea name="" value={message} id="message" cols="30" rows="9" className='border px-4 py-2 rounded bg-gray-200 text-gray-800 font-semibold focus:outline-none resize-none' placeholder='Enter Your Message' required onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <div className='flex justify-end'>
              <input type="submit" value="Submit" className='px-12 rounded cursor-pointer py-2 bg-blue-500 text-white font-bold' /> {/* Submit button */}
            </div>
          </form>
        </div>
      </div>
      <div className='bg-black bg-opacity-90 w-full px-4 py-6 flex md:flex-row flex-col md:gap-2 gap-6 justify-around'>
        {/* Contact information section */}
        <div className='flex flex-col gap-2'>
          <div className='text-white flex items-center gap-2'>
            <AiFillPhone className='md:text-3xl text-2xl' />
            <h4 className='md:text-2xl text-lg font-semibold'>Phone</h4>
          </div>
          <span className='text-white text-lg'>+918275093218</span> {/* Phone number */}
        </div>
        <div className='flex flex-col gap-2'>
          <div className='text-white flex items-center gap-2'>
            <MdEmail className='md:text-3xl text-2xl' />
            <h4 className='md:text-2xl text-lg font-semibold'>Email</h4>
          </div>
          <span className='text-white text-lg'>ceo.dfiie@dbatu.ac.in</span> {/* Email address */}
        </div>
        <div className='flex flex-col gap-2'>
          <div className='text-white flex items-center gap-2'>
            <IoLocation className='md:text-3xl text-2xl' />
            <h4 className='md:text-2xl text-lg font-semibold'>Location</h4>
          </div>
          <iframe className="rounded-3xl h-56 w-120" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d857.6345717000443!2d73.33845126538297!3d18.169984133544318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be82e9b091399f5%3A0x2fac4343fa1e3cae!2sDr.%20Babasaheb%20Ambedkar%20Technological%20University!5e1!3m2!1sen!2sin!4v1730314048525!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Google Map of Dr. Babasaheb Ambedkar Technological University"></iframe>
          <span className='text-white'>
            <b className='md:text-xl text-lg'>DBATU Forum of Innovation, Incubation & Enterprise (DFIIE)</b><br />
             Dr. Babasaheb Ambedkar Technological University, Lonere, Tal- Mangaon, Dist – Raigad.<br />
            Maharashtra (India). 402103<br /> 
          </span>
        </div>
      </div>
    </div>
  );
};

// Export the Contact component wrapped in the MainLayout HOC
export default MainLayout(Contact);
