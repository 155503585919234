import React from 'react';
import { Helmet } from 'react-helmet';
import { focus1, focus2, focus3, focus4, focus5, focus6 } from './ImageImports';
import Image from '../Image';

const FocusArea = () => {
  const images = [focus1, focus2, focus3, focus4, focus5, focus6];
  
  return (
    <>
      <Helmet>
        <title>Focus Areas | DFIIE</title>
      </Helmet>

      <h1 className='text-3xl font-semibold text-center py-4'>Focus Areas</h1>
      <div className='flex items-center justify-center gap-6 flex-wrap py-4 md:px-12 sm:px-4 px-2'>
        {images.map((image, index) => (
          <Image src={image} alt="Focus Area" classData='w-96 shadow rounded' key={index} /> // Added key prop
        ))}
      </div>
    </>
  );
}

export default FocusArea;
