import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Gallery from '../Home/galleryHome';
import ImageSlider from '../ImageSlider';
import ValudPartner from '../Home/Partners';
import dummy from "../images/dummyface.png";

const StartUps = () => {
  const [StartUps, setStartUps] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleDescription = (index) => {
    const newShowFullDescription = [...showFullDescription];
    newShowFullDescription[index] = !newShowFullDescription[index];
    setShowFullDescription(newShowFullDescription);
  };

  // Fetch StartUps from the Django API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/startup`)  // Replace with your actual API URL
      .then((response) => response.json())
      .then((data) => {
        // Sort StartUps by priority (highest priority first)
        const sortedStartUps = data.sort((a, b) => b.priority - a.priority); // Sort by priority
        setStartUps(sortedStartUps);
        setShowFullDescription(new Array(sortedStartUps.length).fill(false)); // Initialize description toggle state
      })
      .catch((error) => console.error('Error fetching startup:', error));
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredStartUps = StartUps.filter(
    (startUp) =>
      startUp.name.toLowerCase().includes(searchTerm) ||
      startUp.sector.toLowerCase().includes(searchTerm) ||
      startUp.description.toLowerCase().includes(searchTerm)
  );

  return (
    <>
      <Helmet>
        <title>StartUps | DFIIE</title>
      </Helmet>
      <ImageSlider />
      <div className="w-full h-full flex flex-col items-center py-6 bg-gray-100">
        <div className="flex flex-col justify-center items-center py-6 md:px-12 px-6 w-11/12">
          <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Find Incubated StartUps!</h1>

          {/* Search Bar */}
          <div className="flex flex-col md:flex-row items-center justify-between mb-6 w-full">
            <input
              type="text"
              placeholder="Search for StartUps..."
              className="w-full md:w-1/2 p-2 border border-gray-300 rounded-lg mb-4 md:mb-0"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          {/* StartUps Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            {filteredStartUps.map((StartUp, index) => (
              <div key={StartUp.id} className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
                {/* StartUp Image */}
                <div className="w-32 h-32 mt-4 object-cover flex item-center mx-auto">
                  <img
                    src={`${StartUp.image || dummy}`}
                    alt={StartUp.name}
                    className="w-auto h-auto rounded object-contain"
                    loading="lazy"
                  />
                </div>
                <div className="p-4 flex flex-col justify-between h-full">
                  {/* StartUp Info */}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 text-center">{StartUp.name}</h3>
                    <p className="text-sm text-gray-700 text-center">{StartUp.sector}</p>
                    <div>
                      <p className="text-xs text-gray-500 mt-2 text-justify">
                        {showFullDescription[index] ? StartUp.description : 
                          StartUp.description.length > 100 ? `${StartUp.description.substring(0, 100)}...` : StartUp.description}
                      </p>
                      {StartUp.description.length > 100 && (
                        <button onClick={() => toggleDescription(index)} className="text-xs text-blue-500">
                          {showFullDescription[index] ? 'Show less' : 'Read more'}
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Action Button */}
                  <div className="flex justify-center mt-2">
                    <a 
                      href={StartUp.link} 
                      onClick={(e) => {
                        if (!StartUp.link) {
                          e.preventDefault();
                          alert('Profile not Available');
                        }
                      }}
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-900 rounded-md px-4 hover:text-blue-500 transition cursor-pointer"
                    >
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ValudPartner />
      <Gallery />
    </>
  );
};

export default StartUps;
