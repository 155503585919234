export const elements = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "About",
        items: [
            {
                name: "About DFIIE",
                path: "/about/dfiie"
            },
            {
                name: "Vision & Mission",
                path: "/about/vision&Mission"
            },
            {
                name: "DFIIE Objective",
                path: "/about/objective"
            }
            ,
            {
                name: "Board of Director",
                path: "/about/director"
            },
            {
                name: "Chairman Message",
                path: "/about/vcMessage"
            },

            {
                name: "Director Message",
                path: "/about/directormessage"
            },
            {
                name: "Executive Team",
                path: "/about/team"
            },
            {
                name: "Incubated Start-ups",
                path: "/about/startups"
            },
            {
                name: "Focus Areas",
                path: "/about/focusareas"
            },

        ]
    },
    {
        name: "StakeHolders",
        items: [
            {
                name: "Government",
                path: "/stakeholders/government"
            },
            {
                name: "Investors",
                path: "/stakeholders/investors"
            },
            {
                name: "Acdemia",
                path: "/stakeholders/acdemia"
            },
            {
                name: "Ecosystem Enablers",
                path: "/stakeholders/ecosystem"
            },
            {
                name: "R & D Institutions",
                path: "/stakeholders/institutions"
            },
            {
                name: "Mentors",
                path: "/stakeholders/mentors"
            },
            {
                name: "Valued Partner",
                path: "/stakeholders/partner"
            }
        ]
    },
    {
        name: "Support",
        items: [
            {
                name: "Legal Support",
                path: "/support/legal"
            },
            {
                name: "Technical Support",
                path: "/support/technical"
            },
            {
                name: "Product Designing",
                path: "/support/product-design"
            },
            {
                name: "Prototype Development Support",
                path: "/support/prototype"
            },
            {
                name: "Funding Support",
                path: "/support/funding"
            },
            {
                name: "Networking Support",
                path: "/support/networking"
            },
            {
                name: "Mentoring Support",
                path: "/support/mentoring"
            },
            {
                name: "Market Connect",
                path: "/support/market"
            },
            {
                name: "IP Support",
                path: "/support/ip"
            },
            {
                name: "HR Support",
                path: "/support/hr"
            }
        ]
    },
    {
        name: "Events",
        path: '/events'

    },
    {
        name: "News & Announcement",
        path: '/news&announcement'
    },

    {
        name: "Gallery",
        path: '/gallery'
    }, {
        name: "Apply Here",
       path: 'https://docs.google.com/forms/d/e/1FAIpQLSfQnRYe3IyoJbqvC-_wkWBQEph5nYt2DvL3DIvCZj9GsO-8QQ/viewform'
    },
    {
        name: "Contact Us",
        path: '/contact'
    },
]