import React from 'react'; // Import React
import { useParams } from 'react-router-dom'; // Import useParams to access route parameters
import Academia from '../components/StakeHolders/Academia'; // Import stakeholder components
import EcosystemEnablers from '../components/StakeHolders/EcosystemEnablers';
import Government from '../components/StakeHolders/Government';
import Investors from '../components/StakeHolders/Investors';
import RAndInstitution from '../components/StakeHolders/RAndInstitution';
import ValuedPartner from '../components/StakeHolders/ValuedPartner';
import MainLayout from '../layout/Main.layout'; // Import the main layout component
import Mentors from '../components/StakeHolders/Mentors'; // Import Mentors component

const StakeHolders = () => {
  const params = useParams(); // Get route parameters

  return (
    <div className='flex flex-col justify-center flex-1 target' id='container'>
      {/* Render the appropriate stakeholder component based on the route parameter 'info' */}
      {params.info === 'acdemia' && <Academia />} {/* Academia stakeholders */}
      {params.info === 'ecosystem' && <EcosystemEnablers />} {/* Ecosystem enablers */}
      {params.info === 'government' && <Government />} {/* Government stakeholders */}
      {params.info === 'investors' && <Investors />} {/* Investors stakeholders */}
      {params.info === 'institutions' && <RAndInstitution />} {/* Research and institutions */}
      {params.info === 'partner' && <ValuedPartner />} {/* Valued partners */}
      {params.info === 'mentors' && <Mentors />} {/* Mentors */}
    </div>
  );
};

// Export the StakeHolders component wrapped in the MainLayout HOC
export default MainLayout(StakeHolders);
