import React from 'react'; // Import React
import { useParams } from 'react-router-dom'; // Import useParams for route parameters
import AboutDFIIE from '../components/About/AboutDFIIE'; // Import AboutDFIIE component
import BoardOfDirector from '../components/About/BoardOfDirector'; // Import BoardOfDirector component
import DFIIEObjective from '../components/About/DFIIEObjective'; // Import DFIIEObjective component
import DirectorMessage from '../components/About/DirectorMessage'; // Import DirectorMessage component
import FocusArea from '../components/About/FocusArea'; // Import FocusArea component
import IncubatedStartUps from '../components/About/IncubatedStartUps'; // Import IncubatedStartUps component
// import TechAdvisorCommity from '../components/About/TechAdvisorCommity'; // Import TechAdvisorCommity component
import VCMessage from '../components/About/VCMessage'; // Import VCMessage component
import VisionAndMission from '../components/About/VisionAndMission'; // Import VisionAndMission component
import Team from '../components/About/Team'; // Import Team component
import MainLayout from '../layout/Main.layout'; // Import MainLayout for consistent layout

const AboutPage = () => {
    const params = useParams(); // Get the route parameters

    return (
        <div>
            {/* Render different components based on the route parameter */}
            {params.info === 'dfiie' && <AboutDFIIE />}
            {params.info === 'director' && <BoardOfDirector />}
            {params.info === 'objective' && <DFIIEObjective />}
            {params.info === 'directormessage' && <DirectorMessage />}
            {params.info === 'focusareas' && <FocusArea />}
            {params.info === 'startups' && <IncubatedStartUps />}
            {params.info === 'team' && <Team />}
            {/* {params.info === 'committee' && <TechAdvisorCommity />} */}
            {params.info === 'vcMessage' && <VCMessage />}
            {params.info === 'vision&Mission' && <VisionAndMission />}
        </div>
    );
};

// Export the AboutPage component wrapped in the MainLayout HOC
export default MainLayout(AboutPage);
