import React, { useState, useEffect } from 'react'; // Import React and hooks
import axios from 'axios'; // Import axios for API requests
import MainLayout from '../layout/Main.layout'; // Import the main layout component
import { PhotoProvider, PhotoView } from 'react-photo-view'; // Import photo viewer components
import SkeletonImage from '../components/SkeletonImage'; // Import skeleton loader for images
import { Helmet } from 'react-helmet'; // Import Helmet for managing the document head
import '../index.css'; // Import the global stylesheet if smooth scrolling is defined there

const Gallery = () => {
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [images, setImages] = useState([]); // State to store images from the API

  // Fetch images from the API when the component mounts
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/gallery/`); // Fetch images from API
        setImages(response.data); // Update state with fetched images
      } catch (error) {
        console.error('Error fetching gallery images:', error); // Log any errors
      } finally {
        setLoading(false); // Set loading to false in both success and failure cases
      }
    };

    fetchImages(); // Call the function to fetch images
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div>
      <Helmet>
        <title>Gallery | DFIIE</title> {/* Set the document title */}
      </Helmet>
      <div className='md:px-8 px-4 py-2'>
        <h1 className='text-4xl font-semibold text-center py-5 mx-auto'>Photos</h1>
        <PhotoProvider>
          <div className="flex justify-center items-center flex-wrap gap-4 py-4">
            {loading ? (
              [...Array(6)].map((_, index) => <SkeletonImage key={index} height={'9rem'} width={'10rem'} />)
            ) : (
              images.map((item, index) => (
                <PhotoView key={index} src={`${item.image}`}>
                  <img
                    src={`${item.image}`}
                    alt={`Gallery item ${index + 1}`}
                    className="md:w-80 w-40 md:h-40 h-36 rounded overflow-hidden object-cover transition-transform transform hover:scale-105 duration-500 ease-in-out"
                    loading="lazy"
                  />
                </PhotoView>
              ))
            )}
          </div>
        </PhotoProvider>
      </div>
    </div>
  );
};

// Export the Gallery component wrapped in the MainLayout HOC
export default MainLayout(Gallery);
