import React from 'react';
import { Helmet } from 'react-helmet';
import VCImage from '../images/vckaleImg.jpg';
import { vCData, vCMessage } from '../../Data/About';

const VCMessage = () => {
  return (
    <>
      <Helmet>
        <title>Hon'ble Chairman Message | DFIIE</title>
      </Helmet>
      <div className='w-full h-full flex flex-col justify-center items-center py-4'>
        <div className='flex flex-col justify-center items-center py-6 md:px-12 px-6 rounded-xl lg:w-11/12 w-11/12' style={{ backgroundColor: "ghostwhite" }}>
          <div className='flex flex-row flex-col-reverse background-banner items-center gap-6'>
            <div className='flex flex-col gap-2'>
              <h1 className='text-2xl font-semibold pb-6 text-[#19191d]'>From the Desk of the Hon'ble Chairman
              </h1>
              
              {vCMessage.map((message) => (
                <p key={message.id} className='text-normal text-justify text-[#19191d]'>{message.text}</p>
              ))}
              
              {vCData.map((data) => (
                <p key={data.id} className={`text-normal ${data.id === 6 ? 'font-semibold mt-4' : ''} text-justify text-[#19191d]`}>{data.text}</p>
              ))}
            </div>
            <img src={VCImage} alt="Director DBATU" className='w-full h-auto rounded overflow-hidden' />
          </div>
        </div>
      </div>
    </>
  );
}

export default VCMessage;
