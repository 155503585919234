import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import { Helmet } from 'react-helmet'; // For managing document head
import GalleryHome from '../Home/galleryHome'; // Importing gallery component
import ValuePartners from '../Home/Partners'; // Importing partners component
import ImageSlider from '../ImageSlider'; // Importing image slider component
// import dbatuforum from '../images/background.png'; // Importing background image

const AboutDFIIE = () => {
  const [aboutDFIIE, setAboutDFIIE] = useState([]); // State to hold about information

  // useEffect to fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/aboutus/`); // Fetch about information
        setAboutDFIIE(response.data); // Update state with fetched data
      } catch (error) {
        console.error('Error fetching data:', error); // Log any errors
      }
    };

    fetchData(); // Call the fetch function
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <>
      <Helmet>
        <title>About | DFIIE</title> {/* Set page title for SEO */}
      </Helmet>
      <main>
        <ImageSlider /> {/* Render the image slider at the top */}
      </main>
      <div className='w-full h-full flex flex-col justify-center items-center py-4'>
        <div className='flex flex-col justify-center items-center py-6 md:px-12 px-6 rounded-xl lg:w-11/12 w-11/12' style={{ backgroundColor: "ghostwhite" }}>
          <div className='flex md:flex-row flex-col-reverse background-banner items-center gap-6'>
            <div className='flex flex-col gap-2'>
              <h1 className='text-2xl font-semibold pb-6 text-[#19191d]'>About DFIIE</h1>
              {aboutDFIIE.map((data) => ( // Map through the fetched about data
                <div key={data.id}> {/* Use unique key for each data item */}
                  <p className='text-normal text-justify text-[#19191d]'>{data.about}</p> {/* Display about text */}
                  {/* Uncomment to show mission and vision if available
                  <p className='text-normal text-justify text-[#19191d]'>Mission: {data.mission}</p>
                  <p className='text-normal text-justify text-[#19191d]'>Vision: {data.vision}</p>
                  */}
                </div>
              ))}
            </div>
            {/* <img src={dbatuforum} alt="About DFIIE" /> Image related to DFIIE */}
          </div>
        </div>
      </div>
      <ValuePartners /> {/* Render partners component */}
      <GalleryHome /> {/* Render gallery component */}
    </>
  );
};

export default AboutDFIIE;
