import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import loadingImg from "../images/Spin.gif";
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

const AboutUsAndEvents = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [aboutUs, setAboutUs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/upcoming-events/`);
        setUpcomingEvents(eventsResponse.data);

        const aboutResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/aboutus/`);
        setAboutUs(aboutResponse.data[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(<div className="text-center">
          <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
        </div>);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className=" w-full h-auto flex flex-col lg:flex-row justify-between items-start lg:p-10">
        <style>
          {`
          @keyframes blink {
            0%, 100% {
              opacity: 1;
            }
            50% {
              opacity: 0.5;
            }
          }
          
          .blink {
            animation: blink 1s infinite;
            }

          .event-image {
            border-radius: 12px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
            transition: transform 0.3s ease;
          }

          .event-image:hover {
            transform: scale(1.05);
            }
            
            @keyframes shadow-blink {
              0%, 100% {
              box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
            }
            50% {
              box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
              }
          }
          
          .shadow-blink {
            animation: shadow-blink 1s infinite;
          }

          .hover-shadow {
            transition: box-shadow 0.3s ease;
          }

          .hover-shadow:hover {
            box-shadow: 0 0 20px rgba(37, 99, 235, 0.5); /* Blue for Vision */
          }

          .hover-shadow-mission:hover {
            box-shadow: 0 0 20px rgba(34, 197, 94, 0.5); /* Green for Mission */
          }
        `}
        </style>

        {/* About Us Section */}
        <div className="w-full lg:w-[60%] flex flex-col mb-8 lg:mb-0">
          <h2 className="text-2xl lg:text-3xl 2xl:text-4xl font-bold mb-4 text-center">About Us</h2>
          {loading ? (
            <div className="text-center">
              <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
            </div>
          ) : error ? (
            <p className="text-center text-red-500">{error}</p>
          ) : aboutUs ? (
            <p className="text-sm md:text-sm lg:text-base text-gray-700 mb-6 text-justify px-4 lg:px-[2.5rem] 3xl:text-xl ">
              {aboutUs.about || "No information available."}
            </p>
          ) : (
            <div className="text-center">
              <img src={loadingImg} alt="No events" className="mx-auto mb-4" />
            </div>
          )}
          <div className="flex flex-col lg:flex-row justify-between items-start gap-4 lg:gap-[2.5rem] p-4 lg:px-[2rem]">
            {/* Vision Section */}
            <div className="p-4 lg:p-[1.25rem] border border-blue-500 rounded-lg shadow-md bg-white w-full lg:w-auto hover-shadow">
              <h3 className="text-xl lg:text-2xl font-semibold mb-4 text-center 2xl:text-3xl">Vision</h3>
              <p className='text-sm md:text-sm lg:text-base 3xl:text-xl text-justify'>{aboutUs?.vision || <div className="text-center">
                <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-10" />
              </div>}</p>
            </div>
            {/* Mission Section */}
            <div className="p-4 lg:p-[1.25rem] border border-green-500 rounded-lg shadow-md bg-white w-full lg:w-auto hover-shadow-mission">
              <h3 className="text-xl lg:text-2xl font-semibold mb-4 text-center 2xl:text-3xl">Mission</h3>
              <p className='text-sm md:text-sm lg:text-base 3xl:text-xl text-justify'>{aboutUs?.mission || <div className="text-center ">
                <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-10" />
              </div>}</p>
            </div>
          </div>
        </div>

        {/* Events Section */}
        <div className="w-full lg:w-[38%] h-auto flex items-center justify-center lg:p-4 pr-0 flex-col px-4">
          <div className="w-full h-auto min-h-[20rem] bg-white shadow-xl rounded-md pt-4 px-4 xl:px-5 shadow-blink m-auto">
            <h2 className="text-2xl lg:text-3xl font-bold mb-4 text-center blink 2xl:text-4xl">Upcoming Events</h2>
            {loading ? (
              <div className="text-center">
              <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
            </div>
            ) : (
              <Swiper
                direction={"horizontal"}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop={true}
                speed={1000}
                modules={[Pagination, Autoplay]}
                className="mySwiper style-1 min-h-[28rem] md:min-h-auto lg:min-h-auto"
              >
                {upcomingEvents.length > 0 ? (
                  upcomingEvents.map((event) => (
                    <SwiperSlide key={event.id}>
                      <Link to="/events">
                        <div className="relative flex items-center justify-center">
                          <img
                            className="w-auto max-w-full max-h-80 sm:h-40 md:h-60 lg:h-80 xl:h-80 rounded-lg event-image m-4"
                            // src={`${process.env.REACT_APP_API_BASE_URL}${event.image}`}
                            src={`${event.image}`}
                            alt="Event"
                          />
                        </div>
                      </Link>
                      <div className="h-auto w-full flex-grow">
                        <p className="text-gray-900 text-lg font-semibold mb-2 text-left">{event.title}</p>
                        <p className="text-sm  text-gray-500 mb-2 text-justify">{event.description}</p>
                        <p className="text-sm text-blue-600 font-semibold text-left">{event.date}</p>
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  <div className="text-center aline-center">
                    <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
                  </div>
                )}
              </Swiper>
            )}
            <div className="text-right my-4">
              <Link to="/events" className=" text-blue-500 hover:text-blue-300 active:text-blue-400 px-2">
                More Events
              </Link>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default AboutUsAndEvents;
