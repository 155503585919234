import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import './style.css';
import axios from 'axios';

import { Autoplay, Pagination } from "swiper";
import SkeletonImage from '../components/SkeletonImage'; // Import skeleton loader for images


export default function ImageSlider() {
  const [posterImages, setPosterImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/home-banners/`);
        const images = response.data.map(banner => banner.image);
        
        // Check if images array is not empty
        if (images.length > 0) {
          setPosterImages(images);
          setLoading(false);
        } else {
          throw new Error("No images found");
        }
      } catch (error) {
        console.error('Error fetching home banners:', error);
        // Retry fetching every 3 seconds if there’s an error
        setTimeout(fetchImages, 3000);
      }
    };

    fetchImages();
  }, []);

  return (
    <>
      {loading ? (
                   [...Array(6)].map((_, index) => <SkeletonImage key={index} height={'4rem'} width={'99rem'} />)

      ) : (
        <Swiper
          direction={"horizontal"}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          loop={true}
          speed={800}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {posterImages.map((image, index) => (
            <SwiperSlide key={index}>
              {/* <img src={`${process.env.REACT_APP_API_BASE_URL}${image}`} alt={`Banner ${index + 1}`} /> */}
              <img src={`${image}`} alt={`Banner ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
}
