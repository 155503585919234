import React from 'react'
import ContentLoader from 'react-content-loader'; // Importing ContentLoader for skeleton loading

// SkeletonImage component to display a loading placeholder
const SkeletonImage = ({ width }) => {
  return (
    <ContentLoader width={width}> {/* Set the width of the loader based on props */}
      <rect x="0" y="0" rx="5" ry="5" width={'100%'} height={'100%'} /> {/* Rectangle to simulate an image */}
    </ContentLoader>
  )
}

export default SkeletonImage