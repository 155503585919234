import React, { useState } from 'react';
import SkeletonImage from './SkeletonImage';

const Image = ({ src, classData, alt, width }) => {
  const [loading, setLoading] = useState(true); // State to manage loading status

  return (
    <>
      {/* Display skeleton while loading */}
      {loading && <SkeletonImage width={width} />}
      
      {/* Main image */}
      <img 
        src={src} 
        alt={alt} 
        onLoad={() => setLoading(false)} 
        className={`${classData} object-cover ${loading ? 'hidden' : 'block'} cursor-pointer`} 
      />
    </>
  );
}

export default Image;
