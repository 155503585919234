import React from 'react';
import { elements } from '../Data/FooterData'; // Importing footer data for navigation links
import { Link } from 'react-router-dom'; // Importing Link for client-side navigation
import { BiCopyright, BiSolidPhone } from 'react-icons/bi'; // Importing icons for copyright and phone
import { BsLinkedin, BsFacebook, BsInstagram,BsWhatsapp } from 'react-icons/bs'; // Importing social media icons
import { AiOutlineForm } from "react-icons/ai"; // Importing form icon
import logo2 from './images/DfiieW.png'; // Importing logo image

const Footer = () => {
    return (
        <footer className='w-full flex flex-col gap-4 py-8 md:px-12 px-2 bg-[#27303e] text-white'>
            {/* Main footer container with background and text color */}

            <div className='w-full flex flex-col justify-around md:flex-row md:gap-4 md:justify-between'>
                {/* Flex container for footer content, adapts to screen size */}

                <div className='flex flex-col gap-1 w-full md:w-1/3'>
                    {/* Column for logo and organization details */}
                    <Link to="/">
                        <img src={logo2} alt='Logo' className='h-auto' style={{maxWidth:'41%'}} />
                        {/* Logo linking to homepage */}
                    </Link>
                    <h1 className='lg:text-xl md:text-l text-lg font-semibold font-serif'>
                        DBATU Forum of Innovation, Incubation & Enterprise (DFIIE),
                    </h1>
                    {/* Title of the organization */}
                    <h6 className='lg:text-lg md:text-base text-sm font-semibold'>
                        Dr. Babasaheb Ambedkar Technological University (DBATU), Lonere, Maharashtra - 402103
                    </h6>
                    {/* Address of the organization */}

                    <div className='flex flex-col md:flex-row pt-10'>
                        {/* Container for social media and contact info */}
                        <div className='pt-0'>
                            <h2 className='font-semibold text-center'>Social Media</h2>
                            {/* Section title for social media links */}
                            <div className='flex gap-2 py-4 items-center  sm:justify-center'>
                                {/* Flex container for social media icons */}
                                <a href="https://www.linkedin.com/company/dbatu-forum-of-innovation-incubation-enterprise/" className='hover:text-blue-500 hover:bg-white hover:rounded-full' rel="noreferrer" target='_blank'>
                                    <BsLinkedin size={30} />
                                    {/* LinkedIn icon with link */}
                                </a>
                                <a href="https://www.facebook.com/p/DBATU-Forum-of-Innovation-Incubation-Enterprise-DFIIE-100091560372128/" className='hover:text-blue-500 hover:bg-white hover:rounded-full m-1' rel="noreferrer" target='_blank'>
                                    <BsFacebook size={30} />
                                    {/* Facebook icon with link */}
                                </a>
                                <a href="https://www.instagram.com/dfiie_innovation/" className='hover:bg-pink-500 hover:rounded-lg' rel="noreferrer" target='_blank'>
                                    <BsInstagram size={30} />
                                    {/* Instagram icon with link */}
                                </a>
                            </div>
                        </div>
                        <div className='pl-0 md:pl-16'>
                            <h2 className='font-semibold text-center'>Contact Us</h2>
                            {/* Section title for contact information */}
                            <div className='flex gap-1 py-4 items-center'>
                                <Link to="/contact" className='hover:text-green-500'>
                                    <BiSolidPhone size={30} />
                                    {/* Phone icon linking to contact page */}
                                </Link>
                                <a href="https://forms.gle/WFBECe8F7zh43Lkq7" className='hover:text-purple-500' rel="noreferrer" target='_blank'>
                                    <AiOutlineForm size={30} />
                                    {/* Form icon linking to external form */}
                                </a>                 
                                <a href="https://whatsapp.com/channel/0029VasUsbELSmbbo8QzFe3j" className='hover:bg-green-500 hover:rounded-lg p-1' rel="noreferrer" target='_blank'>
                                    <BsWhatsapp size={30} />
                                    {/* Form icon linking to external form */}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row w-full md:w-1/2'>
                    {/* Column for navigation links derived from elements */}
                    {elements.map((ele) => (
                        ele.items && (
                            <div key={ele.name} className='flex-1 mb-4 py-4 md:mb-0'>
                                <h4 className='text-xl font-semibold py-3'>{ele.name}</h4>
                                {/* Section title for each category of links */}
                                <div className='flex flex-col gap-1 text-sm'>
                                    {ele.items.map((item) => (
                                        <Link key={item.path} to={item.path} className='hover:text-blue-100'>
                                            {item.name}
                                            {/* Individual link for navigation items */}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>

            <div className='text-gray-400 text-center'>
                <span className='flex items-center justify-center'>
                    <BiCopyright /> DFIIE 2025
                    {/* Copyright notice */}
                </span>
            </div>
        </footer>
    );
}

export default Footer;
