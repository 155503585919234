import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import "swiper/css";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import axios from "axios";
import loadingImg from "../images/Spin.gif";


export default function Partner() {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/value-partners/`);
        setPartners(response.data);
      } catch (error) {
        console.error('Error fetching partners:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  return (
    <>
      {loading ? (
       <div className="text-center">
       <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
     </div>
      ) : (
        <Fade direction="up" triggerOnce={true}>
          <div className="py-8">

          <h3 className='w-full text-[#192f59] font-semibold text-3xl text-center py-4'>Valued Partner</h3>
          <div className="px-8 py-4 flex justify-center items-center">
            <Swiper
              slidesPerView={1}
              spaceBetween={2}
              loop={true}
              autoplay={{ delay: 2000 }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              breakpoints={{
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 30,
                },
              }}
              className="mySwipers"
              >
              {partners.map((partner, index) => (
                <SwiperSlide key={index}>
                  <Link to={`/stakeHolders/partner`}>
                    <div className="flex justify-center items-center bg-gray-100">
                      <img
                        // src={`${process.env.REACT_APP_API_BASE_URL}${partner.image}`}
                        src={`${partner.image}`}
                        alt="Partner"
                        className="h-44 w-auto object-contain"
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
      </div>
        </Fade>
      )}
    </>
  );
}
