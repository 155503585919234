import React, { useEffect, useState } from "react"; 
import MainLayout from "../layout/Main.layout"; 
import { Helmet } from "react-helmet"; 
import axios from "axios"; 
import Image from "../components/Image"; 
import { FaArrowCircleRight } from "react-icons/fa"; 
import loadingImg from "../components/images/Spin.gif"; 
// Loading spinner image


const NewsAndAnnouncement = () => {
  const [newsletters, setNewsletters] = useState([]); 
  const [announcements, setAnnouncements] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newslettersResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/newsletters/`);
        const sortedNewsletters = newslettersResponse.data.sort((a, b) => b.id - a.id);
        setNewsletters(sortedNewsletters);

        const announcementsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/announcement/`);
        const sortedAnnouncements = announcementsResponse.data.sort((a, b) => b.id - a.id);
        setAnnouncements(sortedAnnouncements);

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>News & Announcement | DFIIE</title>
      </Helmet>

      <div className="w-full h-full flex flex-col justify-center items-center">
      {announcements.length > 0 && (
        <div className="py-6 md:px-12 px-6 rounded-xl lg:w-10/12 w-11/12">

          <div className="text-center font-semibold text-3xl py-2.5">Announcement</div>

          {/* Loading State */}
          {loading && (
            
            <div className="text-center">
              <img src={loadingImg} alt="Loading..." className="mx-auto mb-4 h-20" />
              <p>Loading announcements and newsletters...</p>
            </div>
          )}

          {/* Error Message */}
          {error && (
            <div className="text-center text-red-600">
              <p>{error}</p>
            </div>
          )}

          {/* Announcements Section */}
          <div className="items-start">
            {announcements.map((announcement, index) => (
              <div key={index} className="flex flex-row items-center gap-3">
                <FaArrowCircleRight />
                <a 
                  href={announcement.link} 
                  target="_blank" 
                  rel="noreferrer" 
                  className="animate-blinking transition-transform duration-300 ease-in-out hover:scale-105"
                >
                  <h2><b>{announcement.announcement}</b></h2>
                </a>
              </div>
            ))}
          </div>
        </div>
        )}

        <div className="flex flex-col justify-center items-center py-2.5 rounded-xl lg:w-10/12 w-11/12">
          <h1 className="text-center font-semibold text-3xl py-2.5">Newsletter</h1>

          {/* No Newsletters Message */}
          {!loading && !error && newsletters.length === 0 && (
            <p className="text-center text-gray-600">No newsletters available at the moment.</p>
          )}

          {/* Newsletters Grid */}
          <div className="grid sm:grid-cols-3 md:grid-cols-5 gap-4">
            {newsletters.map((item, index) => (
              <div key={index} className="border p-2 shadow rounded bg-white">
                <a href={item.drive_link} target="_blank" rel="noreferrer" style={{ cursor: "pointer" }}>
                  <Image
                    // src={`${process.env.REACT_APP_API_BASE_URL}${item.image}`} 
                    src={`${item.image}`} 
                    width={"100%"} 
                    alt={item.newslettername} 
                  />
                  <h1 className="font-semibold text-1xl py-2.5">{item.name}</h1> 
                  <p className="text-1l rounded-xl">{item.description}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout(NewsAndAnnouncement);
