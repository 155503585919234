export const aboutDFIIE = [
    "DBATU Forum of Innovation, Incubation & Enterprise (DFIIE) Lonere is created for fostering innovation by supporting & promoting technology-based startups and providing an ecosystem for their growth by Incubating in the Center. The Center was established in November 2019 as section 8 company in Dr. Babasaheb Ambedkar Technological University (DBATU)Lonere. The Incubation Center is actively involved in developing the paradigm of entrepreneurial mindset & environment of ideating, creating and commercializing the ventures at the institute or any other startups aligned to the core theme of center. The Center is supported by Maharashtra State Innovation Society (MSInS), Government of Maharashtra",
    "Through our incubation center, we aim to provide startups with access to affordable office space, mentorship, training, funding opportunities, and networking opportunities with other entrepreneurs and industry experts. We believe that by providing these resources and support, we can help startups accelerate their growth and create new jobs and opportunities in the state.",

]

export const DfiieObjective = [
    "▪️To promote innovation and assist start-up companies by establishing and providing physical infrastructure.",
    "▪️To provide incubation services to innovation and start-up companies by facilitating technology and management consulting services and guidance.",
    "▪️To promote entrepreneurial activities and create culture of entrepreneurship and innovation leading to start-up companies.",
    "▪️To build awareness about the importance of entrepreneurship among the students and local community.",
    "▪️To build a vibrant startup ecosystem, by establishing a network between academia, financial institutions, industries, and other institutes.",
    "▪️To promote new technology/ knowledge/ innovation-based startups.",
    "▪️To promote design thinking process among the incubates and provide the platform for speedy commercialization of technology.",

]

export const vision = [
    "“To develop an ecosystem that enables entrepreneurs to build successful science and technology businesses with global potential”"
]
export const mission = [
    "To stimulate the establishment and growth of technology-based start-up companies and other compatible businesses. By fulfilling this mission, the incubator would contribute to job creation, and provide for enhanced economic health to the region."
]

export const vCMessage = [
    {id:2,text: "Welcome to the DBATU Forum of Innovation, Incubation & Enterprise (DFIIE), a premier Technology Business Incubator established under the aegis of Dr. Babasaheb Ambedkar Technological University, Maharashtra's state technological university. At DFIIE, we are dedicated to creating a dynamic ecosystem that empowers innovators, startups, and entrepreneurs to transform their ideas into impactful and sustainable solutions. Our mission is to nurture talent, foster innovation, and provide holistic support to help entrepreneurs overcome challenges and achieve sustainable growth. "},

    {id:5,text: "Our incubator offers state-of-the-art infrastructure, expert mentorship, funding facilitation, and access to a vibrant network of industry leaders and investors. These resources are strategically designed to accelerate the journey from concept to market, while cultivating a culture of creativity, collaboration, and resilience. "},

    {id:3,text: "With the valuable support of the Maharashtra State Innovation Society and other key stakeholders, we aim to drive regional and national development by generating employment opportunities, advancing technology-driven solutions, and fostering social and economic progress. At DFIIE, we firmly believe that entrepreneurship is a catalyst for change, and we strive to create an environment where entrepreneurs can thrive and make a meaningful impact on society. "},

    {id:4,text: "I encourage you to explore the opportunities at DFIIE and join us in shaping a future driven by innovation and entrepreneurial excellence. Together, let us build solutions that not only solve today's challenges but also inspire the generations to come. "},

]
export const vCData = [
    {id:6,text: "Colonel Prof. (Dr.) K. V. Kale,"},
    {id:7,text: "Hon'ble Vice-Chancellor of Dr. Babasaheb Ambedkar Technological University&"},
    {id:8,text: "Chairman - DBATU Forum of Innovation, Incubation & Enterprise (DFIIE)"},

]

export const directorMessage = [
    "It is with great enthusiasm that I welcome you to the DBATU Forum of Innovation, Incubation & Enterprise (DFIIE). Our Incubation Centre represents the commitment of Dr. Babasaheb Ambedkar Technological University to fostering innovation, entrepreneurship, and transformative growth.",
    "As a university-driven initiative, our Incubation Centre serves as a bridge between academic excellence and real-world enterprise. We provide an enabling environment where ideas are nurtured, startups are supported, and innovation thrives. Our aim is to empower individuals with the tools, knowledge, and networks they need to navigate the entrepreneurial journey and achieve sustainable success.",
    "Equipped with state-of-the-art infrastructure, expert mentorship, access to funding, and vibrant industry connections, the Incubation Centre is designed to help innovators translate their vision into impactful solutions. We believe that universities are the breeding grounds for ideas that can shape the future, and through this initiative, we strive to bring those ideas to life.",
    "Collaboration and inclusivity are the cornerstones of our approach. By fostering partnerships with industry leaders, policymakers, and academic experts, we aim to create a vibrant ecosystem that fuels innovation, drives economic growth, and addresses societal challenges.",
    "I invite you to join this exciting journey of innovation and entrepreneurship. Together, let us build a future where ideas lead to impact, and ambition meets opportunity.",


]
export const directorData = [
    "Prof. S. L. Nalbalwar",
    "Director-DBATU Forum of Innovation, Incubation & Enterprise (DFIIE)"

]

export const focusArea = [
    " ", "Augmented Reality, Big Data and Analytics, Image Processing  and Recognition",
    "Bio Technology",

    "Building Materials/Construction Technology",
    "Electricity, New and Renewable Energy and Environmental sustainability",
    "Energy conservation, Energy efficient Motors and Equipments",
    "Electric Mobility",
    "Emission Control Systems and products",
    "Solar Photovoltaic systems and design optimization",
    "Education",
    "Information & Communication Technology (ICT)",
    "Manufacturing Automation and Engineering",
    "Additive Manufacturing / 3D Printing",
    "Water, Sanitation and Solid-Liquid Waste Management",
    "Waste heat Recovery Systems",
    "Telecommunication",
    "Cyber Security",
    "Artificial Intelligence and Remote Monitoring",
    "Drone Technology",
    "Food, Agriculture & Allied Sectors",
    "Other emerging areas of social / national importance"
]