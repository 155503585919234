import React, { useEffect, useState } from 'react'; // Import React and hooks
import axios from 'axios'; // Import axios for HTTP requests
import { Helmet } from 'react-helmet'; // Import Helmet for managing document head
import '../style.css'; // Import custom styles
import Image from '../Image'; // Import custom Image component
import { valuedPartner } from '../../Data/StakeHolders'; // Import data for valued partners
import partnerImage from "../images/partner.png"; // Import partner image

const ValuedPartner = () => {
  const [images, setImages] = useState([]); // State to hold partner images

  // useEffect hook to fetch partner images on component mount
  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Fetch images from the API
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/value-partners/`);
        setImages(response.data); // Set fetched images to state
      } catch (error) {
        console.error('Error fetching images:', error); // Log any errors
      }
    };

    fetchImages(); // Call the fetch function
  }, []); // Empty dependency array means this runs once on mount

  return (
    <>
      <Helmet>
        <title>Valued Partners | DFIIE</title> {/* Set the document title */}
      </Helmet>
      <div className='w-full h-full flex flex-col justify-center items-center py-4'>
        <div className='flex flex-col justify-center items-center py-6 md:px-12 px-6 rounded-xl lg:w-11/12 w-11/12' style={{ backgroundColor: "ghostwhite" }}>
          <div className='flex md:flex-row flex-col-reverse background-banner items-center gap-6'>
            <div className='flex flex-col gap-2'>
              <h1 className='text-2xl font-semibold pb-6 text-[#19191d]'>Valued Partners</h1>
              {/* Map through valuedPartner data and display each entry */}
              {valuedPartner.map((data, index) => (
                <p key={index} className='text-normal text-justify text-[#19191d]'>{data}</p> // Added key prop for uniqueness
              ))}
            </div>
            {/* Partner image displayed alongside the text */}
            <img src={partnerImage} style={{ width: '20%' }} className='img11' alt="academia" />
          </div>
        </div>
      </div>
      <div className='flex items-center justify-center gap-6 flex-wrap py-4 md:px-12 sm:px-4 px-2'>
        {/* Map through images and render the Image component for each */}
        {images.map((image) => (
          // <Image key={image.id} src={`${process.env.REACT_APP_API_BASE_URL}${image.image}`} alt="FocusArea" classData='sm:w-48 w-44 shadow' />
          <Image key={image.id} src={`${image.image}`} alt="FocusArea" classData='sm:w-48 w-44 shadow' />
        ))}
      </div>
    </>
  );
}

export default ValuedPartner;
