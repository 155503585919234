import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation between routes
import axios from 'axios'; // Import axios for API requests
import "swiper/css"; // Import Swiper styles
import "swiper/css/pagination"; // Import pagination styles
import "swiper/css/navigation"; // Import navigation styles
import "swiper/css/autoplay"; // Import autoplay styles
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import { Navigation, Autoplay } from "swiper";
import loadingImg from "../images/Spin.gif";
 // Import Swiper modules

export default function Gallery() {
  const [galleryImages, setGalleryImages] = useState([]); // State to hold gallery images
  const swiperRef = useRef(null); // Reference to Swiper instance

  // Fetch gallery images from API on component mount
  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/gallery/`);
        setGalleryImages(response.data); // Set fetched images to state
      } catch (error) {
        console.error('Error fetching gallery images:', error); // Log any errors
      }
    };

    fetchGalleryImages(); // Call the fetch function
  }, []); // Empty dependency array ensures this runs only once on mount

  // Start autoplay when images are loaded
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.start(); // Start autoplay
    }
  }, [galleryImages]); // Dependency on galleryImages ensures this runs when images are loaded

  return (
    <>
      <div className="bg-white">
        <h3 className="w-full text-[#192f59] font-semibold text-4xl text-center py-4">Gallery</h3>

        <div className="px-4 py-5 flex justify-center items-center">
          <Swiper
            ref={swiperRef} // Reference to Swiper
            slidesPerView={1} // Number of slides to show at once
            spaceBetween={10} // Space between slides
            loop={true} // Enable looping of slides
            autoplay={{ delay: 2000, disableOnInteraction: false }} // Autoplay settings
            speed={1000} // Transition speed for slides
            navigation={{ clickable: true }} // Enable navigation buttons
            modules={[Navigation, Autoplay]} // Register modules
            className="mySwipers" // Custom class for styling
            breakpoints={{ // Responsive settings
              640: { slidesPerView: 1, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 10 },
              1024: { slidesPerView: 3, spaceBetween: 10 },
              1436: { slidesPerView: 4, spaceBetween: 10 },
              1800: { slidesPerView: 5, spaceBetween: 10 },
            }}
       // Attach the slide change handler
          >
            {galleryImages.length > 0 ? ( // Check if images are loaded
              galleryImages.map((image) => ( // Map over gallery images
                <SwiperSlide key={image.id}> {/* Use unique key for each slide */}
                  <div className="flex justify-center items-center">
                    <Link to={`/gallery/`}> {/* Link to gallery detail page */}
                      <img
                        // src={`${process.env.REACT_APP_API_BASE_URL}${image.image}`} // Construct image URL
                        src={`${image.image}`} // Construct image URL
                        alt={`Gallery item ${image.id}`} // Alt text for image
                        className={`w-full max-w-xs h-40 object-cover sm:h-40 sm:w-auto transition-transform transform hover:scale-105 duration-500 ease-in-out rounded-3xl p-4`} // Conditional class for border radius
                      />
                    </Link>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <div className="text-center">
              <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
            </div>
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
}
