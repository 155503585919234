import React from 'react'; // Import React
import Header from '../components/Header'; // Import Header component
import Navbar from '../components/Navbar'; // Import Navbar component
import Footer from '../components/Footer'; // Import Footer component

// Higher-order component to wrap other components with a common layout
const MainLayout = (Components) => ({ ...props }) => {
  return (
    <div className='flex flex-col h-full overflow-x-hidden'>
      <Header /> {/* Renders the Header */}
      <Navbar /> {/* Renders the Navbar */}
      <main>
        <Components {...props} /> {/* Renders the passed component with props */}
      </main>
      <Footer /> {/* Renders the Footer */}
    </div>
  );
}

export default MainLayout; // Export the MainLayout component
