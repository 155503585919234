import React from 'react'; // Import React
import Header from '../components/Header'; // Import Header component
import Navbar from '../components/Navbar'; // Import Navbar component
import ImageSlider from '../components/ImageSlider'; // Import ImageSlider component
import Footer from '../components/Footer'; // Import Footer component

// Higher-order component to wrap other components with a common layout
const Homelayout = (Components) => ({ ...props }) => {
  return (
    <div className='flex flex-col h-full'>
      <Header /> {/* Renders the Header */}
      <Navbar /> {/* Renders the Navbar */}
      <main>
        <ImageSlider /> {/* Renders the ImageSlider */}
        <Components {...props} /> {/* Renders the passed component with props */}
        <Footer /> {/* Renders the Footer */}
      </main>
    </div>
  );
}

export default Homelayout; // Export the Homelayout component
