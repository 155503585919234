import React from "react"; // Import React
import { Vcmessageforhomee, directormessageforhomee } from "../../Data/Homevc"; // Import messages for VC and Director
import { Fade } from "react-awesome-reveal"; // Import Fade animation for transitions
import directorImg from '../images/dircterpic.jpg'; // Import Director's image
import vcImage from '../images/vcpic.jpg'; // Import VC's image
import { Link } from "react-router-dom"; // Import Link for navigation

const Vcmessageforhome = () => {
  return (
    <>
      <div className="bg-white">

        {/* VC Message Section */}
        {/* <h1 className="md:text-5xl text-4xl text-center font-semibold text-[#192f59] py-10 ">Chairman & Director</h1> */}
        <div className="flex md:flex-row flex-col md:item-center">
          <div className="w-full h-full flex flex-col justify-center items-center py-4">
            <div className="flex flex-col justify-center items-center py-6 md:px-8 px-6">
              <div className="flex flex-col items-center gap-8">
                {/* VC Image with fade effect */}
                <Fade direction='left' className="md:w-1/3 w-2/5" triggerOnce={true}>
                  <img
                    src={vcImage}
                    className="h-full w-full object-cover rounded-full"
                    alt="vice chancellor"
                  />
                </Fade>
                {/* VC Message with fade effect */}
                <Fade direction='left' triggerOnce={true} className="md:w-3/4">
                  <div className="flex flex-col gap-4">
                    <h1 className="md:text-4xl text-2xl directormessage font-semibold lg:pb-6 text-[#192f59]">
                      From the Desk of the Hon'ble Chairman
                    </h1>
                    {Vcmessageforhomee.map((data, index) => (
                      <p key={index} className="md:text-[17px] text-justify text-[#19191d]">
                        {data}{index === directormessageforhomee.length - 2 && (
                          <Link to={'/about/vcMessage'} className="text-blue-600 text-sm hover:text-blue-800 font-semibold transition-colors ease-in-out duration-100">
                            Read More
                          </Link>
                        )}
                      </p>
                    ))}
                    <p className="text-[18px] text-justify vctext text-blue-700" style={{ fontWeight: "700" }}>
                      - Colonel Prof. (Dr.) K. V. Kale
                    </p>
                    {/* <div className="flex justify-items-start mt-3">
                      <Link to={'/about/vcMessage'} className="rounded border px-2 py-1.5 text-blue-800 border-blue-800 hover:bg-blue-800 hover:text-white font-semibold transition-colors ease-in-out duration-100">
                        Read More
                      </Link>
                    </div> */}
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          <div className="hidden md:block border-l-2 border-gray-300 h-96 rounded 2xl:mt-96 mt-72"></div>

          {/* Director Message Section */}
          <div className="w-full h-full flex flex-col justify-center items-center py-4">
            <div className="flex flex-col justify-center items-center py-6 md:px-8 px-6">
              <div className="flex  flex-col items-center gap-8">
                {/* Director Image with fade effect */}
                <Fade direction='right' className="md:w-1/3 w-2/5" triggerOnce={true}>
                  <img
                    src={directorImg}
                    className="h-full w-full object-cover rounded-full"
                    alt="director"
                  />
                </Fade>
                {/* Director Message with fade effect */}
                <Fade direction='right' triggerOnce={true} className="md:w-3/4">
                  <div className="flex flex-col gap-4">
                    <h1 className="md:text-4xl text-2xl directormessage font-semibold text-[#192f59]">
                      From the Desk of the Director
                    </h1>
                    {directormessageforhomee.map((data, index) => (
                      <p key={index} className="md:text-[17px] text-justify text-[#19191d]">
                        {data}
                        {index === directormessageforhomee.length - 1 && (
                          <Link to={'/about/directormessage'} className="text-blue-600 text-sm hover:text-blue-800 font-semibold transition-colors ease-in-out duration-100">
                            Read More
                          </Link>
                        )}
                      </p>
                    ))}
                    <p className="text-[18px] text-justify vctext text-blue-700" style={{ fontWeight: "700" }}>
                      - Prof. S. L. Nalbalwar
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vcmessageforhome;
