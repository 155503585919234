import React, { useEffect, useState } from "react"; // Import necessary hooks and components from React
import { Link } from "react-router-dom"; // Import Link component for navigation
import { Fade } from "react-awesome-reveal"; // Import Fade animation
import { Pagination, Navigation, Autoplay } from "swiper"; // Import Swiper modules for pagination, navigation, and autoplay
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper and SwiperSlide components
import "swiper/css"; // Import Swiper CSS
import "swiper/css/pagination"; // Import pagination CSS
import "swiper/css/navigation"; // Import navigation CSS
import "swiper/css/autoplay"; // Import autoplay CSS
import axios from 'axios'; // Import axios for making HTTP requests
import loadingImg from "../images/Spin.gif";


// Functional component for rendering newsletters
export default function NewsLetter() {
  // State variables for storing newsletters, loading state, and error messages
  const [newss, setNewss] = useState([]); // State to hold newsletter data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to hold any error messages

  // useEffect hook to fetch newsletters on component mount
  useEffect(() => {
    const fetchNewsletters = async () => {
      try {
        // Make a GET request to fetch newsletters from the API
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/newsletters/`);
        setNewss(response.data); // Set the fetched data to state
      } catch (error) {
        console.error("Error fetching newsletters:", error); // Log error to console
        setError(<div className="text-center">
          <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
        </div>); // Set error message
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchNewsletters(); // Call the fetch function
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <>
      <style>
        {`
          /* Apply scaling to the active slide */
          .swiper-slide-active {
            transform: scale(1.1); /* Increase size of active slide */
            z-index: 3; /* Bring active slide to the front */
            opacity: 1; /* Fully visible */
          }

          .mySwiper .swiper-slide-prev,
          .mySwiper .swiper-slide-next {
            transform: scale(0.8); /* Slightly smaller for adjacent slides */
            z-index: 2; /* Lower z-index than active slide */
            opacity: 0.7; /* Slightly faded for adjacent slides */
          }

          .swiper-slide {
            transition: transform 0.4s ease, opacity 0.4s ease; /* Smooth transition for transforms */
          }

          /* Responsive adjustments */
          @media (max-width: 1000px) {
            .swiper-slide-active {
              transform: scale(1.05); /* Slightly less scale on smaller screens */
            }

            .mySwiper .swiper-slide-prev,
            .mySwiper .swiper-slide-next {
              transform: scale(0.7); /* Less scale for adjacent slides on smaller screens */
              opacity: 0.7; /* Adjust opacity for better visibility */
            }
          }
        `}
      </style>

      <Fade direction="up" triggerOnce={true} className="bg-white"> {/* Fade in the content */}
        <h3 className="py-5 text-[#192f59] font-semibold text-3xl text-center">NewsLetter</h3>
        <div className="py-6 flex justify-center items-center">
          {loading ? ( // Conditional rendering based on loading state
            <div className="text-center">
          <img src={loadingImg} alt="No events" className="mx-auto mb-4 h-20" />
        </div>// Show loading message
          ) : error ? ( // If there's an error, show the error message
            <p className="text-red-500">{error}</p>
          ) : (
            <Swiper // Render Swiper component for sliding newsletters
              slidesPerView={3} // Number of slides to show at once
              spaceBetween={2.5} // Space between slides in rem (10px = 0.625rem)
              loop={true} // Enable looping of slides
              centeredSlides={true} // Center the active slide
              autoplay={{ delay: 2000, disableOnInteraction: false }} // Autoplay settings
              navigation={true} // Enable navigation buttons
              modules={[Pagination, Navigation, Autoplay]} // Use Swiper modules
              className="mySwiper w-full max-w-[85rem] h-[43.75rem] mx-auto" // Custom classes for styling (70rem = 43.75rem)
              breakpoints={{ // Responsive breakpoints for different screen sizes
                640: {
                  slidesPerView: 1, // Show 1 slide on small screens
                  spaceBetween: 2.5, // Space between slides in rem
                },
                768: {
                  slidesPerView: 3, // Show 2 slides on medium screens
                  spaceBetween: 2, // Space between slides in rem (20px = 1.25rem)
                },
                1024: {
                  slidesPerView: 3, // Show 3 slides on larger screens
                  spaceBetween: 7.5, // Space between slides in rem (30px = 1.875rem)
                },
              }}
            >
              {newss.map((news, index) => ( // Map through newsletters to create slides
                <SwiperSlide
                  key={news.id || index} // Use unique id or index as key
                  className="slider-slide flex justify-center items-center overflow-hidden" // Custom classes for slide styling
                >
                  <Link to={`/news&announcement`}> {/* Link to detailed announcement page */}
                    <div className="slide-content w-full h-full flex justify-center items-center overflow-hidden">
                      <img
                        // src={`${process.env.REACT_APP_API_BASE_URL}${news.image}`} // Image source from API
                        src={`${news.image}`} // Image source from API
                        alt={news.title || `newsletter-${index + 1}`} // Alt text for accessibility
                        className="slide-image h-full w-72 object-contain transition-transform duration-300 ease-in-out rounded-md" // Custom classes for image styling
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </Fade>
    </>
  );
}
