import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import mentordummy from "../images/dummyface.png";
import Gallery from '../Home/galleryHome';
import ImageSlider from '../ImageSlider';
import ValudPartner from '../Home/Partners';

const Mentors = () => {
  const [mentors, setMentors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/mentors/`)
      .then((response) => response.json())
      .then((data) => {
        // Sort mentors by priority
        const sortedMentors = data.sort((a, b) => b.priority - a.priority); // Sort by priority, highest first
        setMentors(sortedMentors);
      })
      .catch((error) => console.error('Error fetching mentors:', error));
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredMentors = mentors.filter(
    (mentor) =>
      mentor.name.toLowerCase().includes(searchTerm) ||
      mentor.role.toLowerCase().includes(searchTerm) ||
      mentor.bio.toLowerCase().includes(searchTerm)
  );

  return (
    <>
      <Helmet>
        <title>Mentors | DFIIE</title>
      </Helmet>
      <ImageSlider />
      <div className="w-full h-full flex flex-col items-center py-6 bg-gray-100">
        <div className="flex flex-col justify-center items-center py-6 md:px-12 px-6 w-11/12">
          <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Find a Mentor!</h1>
          
          {/* Search Bar */}
          <div className="flex flex-col md:flex-row items-center justify-between mb-6 w-full">
            <input
              type="text"
              placeholder="Search for mentors..."
              className="w-full md:w-1/2 p-2 border border-gray-300 rounded-lg mb-4 md:mb-0"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          {/* Mentors Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            {filteredMentors.map((mentor) => (
              <div key={mentor.id} className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
                <div className="w-32 h-32 rounded-full object-cover mx-auto mt-4">
                  <img
                    src={`${mentor.image || mentordummy}`}
                    alt={mentor.name}
                    className="w-full h-full rounded-full"
                    loading="lazy"
                  />
                </div>
                <div className="p-4 flex flex-col justify-between h-full">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 text-center">{mentor.name}</h3>
                    <p className="text-sm text-gray-700 text-center">{mentor.role}</p>
                    <p className="text-xs text-gray-500 mt-2 text-center">{mentor.bio}</p>
                  </div>
                  <div className="flex justify-center mt-4">
                    <a
                      href={mentor.link}
                      onClick={(e) => {
                        if (!mentor.link) {
                          e.preventDefault();
                          alert('Mentor link not found');
                        }
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-900 rounded-md px-4 py-2 hover:text-blue-500 transition cursor-pointer"
                    >
                      View Profile
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ValudPartner />
      <Gallery />
    </>
  );
};

export default Mentors;
