import React from 'react';
import { Helmet } from 'react-helmet';
import { directorData, directorMessage } from '../../Data/About';
import DirectorImage from '../images/directorAboutImg.jpg';

const DirectorMessage = () => {
  return (
    <>
      <Helmet>
        <title>Director Message | DFIIE</title>
      </Helmet>
      <div className='w-full h-full flex flex-col justify-center items-center py-4'>
        <div className='flex flex-col justify-center items-center py-6 md:px-12 px-6 rounded-xl lg:w-11/12 w-11/12' style={{ backgroundColor: "ghostwhite" }}>
          <div className='flex flex-col-reverse background-banner items-center gap-6'>
            <div className='flex flex-col gap-2'>
              <h1 className='text-2xl font-semibold pb-6 text-[#19191d]'>From the Desk of the Director</h1>
              {directorMessage.map((data, index) => (
                <p key={index} className='text-normal text-justify text-[#19191d]'>{data}</p>
              ))}
              {directorData.map((data, idx) => (
                <p key={idx} className={`text-normal ${idx === 0 ? 'font-semibold mt-4' : ''} text-justify text-[#19191d]`}>
                  {data}
                </p>
              ))}
            </div>
            <img src={DirectorImage} className='w-full h-auto rounded overflow-hidden' alt="Director DBATU" />
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectorMessage;
