import React from 'react'; // Import React
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'; // Import necessary routing components
import 'react-photo-view/dist/react-photo-view.css'; // Import styles for the photo viewer
import './App.css'; // Import custom CSS styles
import HomePage from './pages/HomePage'; // Import HomePage component
import Contact from './pages/Contact'; // Import Contact component
import NewsAndAnnouncement from './pages/NewsAndAnnouncement'; // Import NewsAndAnnouncement component
import Gallery from './pages/Gallery'; // Import Gallery component
import AboutPage from './pages/AboutPage'; // Import AboutPage component
import Events from './pages/Events'; // Import Events component
import StakeHolders from './pages/StakeHolders'; // Import StakeHolders component
import Support from './pages/Support'; // Import Support component

function App() {
  // ScrollToTop component scrolls to the top of the page on route change
  const ScrollToTop = () => {
    const { pathname } = useLocation(); // Get the current pathname

    // Effect to scroll to the top on pathname change
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null; // No UI rendered
  };

  return (
    <div className="App h-full">
      <BrowserRouter>
        <ScrollToTop /> {/* Ensure the page scrolls to top on navigation */}
        <Routes>
          {/* Define routes for the application */}
          <Route path='/' element={<HomePage />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/news&announcement' element={<NewsAndAnnouncement />} />
          <Route path='/about/:info' element={<AboutPage />} />
          <Route path='/events' element={<Events />} />
          <Route path='/stakeHolders/:info' element={<StakeHolders />} />
          <Route path='/support/:info' element={<Support />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App; // Export the App component
