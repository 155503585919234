import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // For managing document head
import axios from 'axios'; // Importing axios for API requests
import dbatuforum from '../images/vision.png'; // Importing vision image
import dbatuforum1 from '../images/mission.jpg'; // Importing mission image
import GalleryHome from '../Home/galleryHome'; // Importing gallery component
import ValuePartners from '../Home/Partners'; // Importing partners component
import ImageSlider from '../ImageSlider'; // Importing image slider component

const VisionAndMission = () => {
  const [vision, setVision] = useState([]); // State to hold vision data
  const [mission, setMission] = useState([]); // State to hold mission data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state for handling fetch errors

  // useEffect to fetch vision and mission data on component mount
  useEffect(() => {
    const fetchAboutUsData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/aboutus/`); // Fetching data from API
        const aboutData = response.data[0]; // Get the first object from the array
        setVision([aboutData.vision]); // Set vision state
        setMission([aboutData.mission]); // Set mission state
      } catch (error) {
        console.error('Error fetching About Us data:', error); // Log any errors
        setError('Failed to load data. Please try again later.'); // Set error message
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchAboutUsData(); // Call the fetch function
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <>
      <Helmet>
        <title>Vision & Mission | DFIIE</title> {/* Set page title for SEO */}
      </Helmet>
      <ImageSlider /> {/* Render the image slider at the top */}

      <div className='w-full h-full flex flex-col justify-center items-center py-4'>
        <div className='flex flex-col justify-center items-center py-6 md:px-12 px-6 rounded-xl lg:w-3/4 w-11/12'>
          {loading ? ( // Conditional rendering based on loading state
            <p>Loading...</p>
          ) : error ? ( // Display error message if there's an error
            <p className="text-red-500">{error}</p>
          ) : (
            <>
              {/* Vision section */}
              <div className='flex md:flex-row flex-col-reverse background-banner items-center gap-6'>
                <div className='flex flex-col gap-2'>
                  <h1 className='text-2xl font-semibold pb-3 text-[#19191d]'>Vision</h1>
                  {vision.map((data, index) => ( // Map through vision data
                    <p key={index} className='text-sm gap-3 text-justify text-[#19191d]' style={{ fontSize: "20px" }}>
                      {data}
                    </p>
                  ))}
                </div>
                <img src={dbatuforum} width={"281px"} alt="About DFIIE" /> {/* Vision image */}
              </div>
              {/* Mission section */}
              <div className='flex md:flex-row flex-col-reverse background-banner items-center gap-6'>
                <div className='flex flex-col gap-2'>
                  <h1 className='text-2xl font-semibold pb-3 text-[#19191d]'>Mission</h1>
                  {mission.map((data, index) => ( // Map through mission data
                    <p key={index} className='text-sm text-justify text-[#19191d]' style={{ fontSize: "19px" }}>
                      {data}
                    </p>
                  ))}
                </div>
                <img className='pt-4' src={dbatuforum1} width={"281px"} alt="About DFIIE" /> {/* Mission image */}
              </div>
            </>
          )}
        </div>
      </div>
      <ValuePartners /> {/* Render partners component */}
      <GalleryHome /> {/* Render gallery component */}
    </>
  );
};

export default VisionAndMission;
