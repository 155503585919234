import React, { useState } from 'react';
import SkeletonImage from '../SkeletonImage'; // Import skeleton loader for images
import { Helmet } from 'react-helmet'; // For managing document head

// Importing team member images
import Kiwalekar from "../images/ceo.jpg";
import sameerPunaskar from "../images/sameerPunaskar.jpg";
import sheetalSharma from "../images/sheetalSharma.jpg";

const Team = () => {
  // Array of team members with their details
  const teamMembers = [
    {
      "name": "Dr. Navin Khandare",
      "image": Kiwalekar,
      "designation": "Chief Executive Officer",
      "linkedin": "https://www.linkedin.com/in/drnavinkhandare/" // Add LinkedIn URL
    },
    {
      "name": "Sameer Punaskar",
      "image": sameerPunaskar,
      "designation": "Incubation Manager",
      "linkedin": "https://www.linkedin.com/in/sameer-punaskar-415757b/" // Add LinkedIn URL
    },
    {
      "name": "Sheetal Sharma",
      "image": sheetalSharma,
      "designation": "Accounts Officer",
      "linkedin": "https://www.linkedin.com/in/sheetal-sharma-840a34296/" // Add LinkedIn URL
    },
  ];

  const [loading, setLoading] = useState(true); // Loading state to manage image loading

  return (
    <>
      <Helmet>
        <title>Executive Team | DFIIE</title> {/* Set page title for SEO */}
      </Helmet>
      <div className='md:px-8 px-4 py-4'>
        <h1 className='md:text-3xl text-2xl font-semibold text-center py-6'>Executive Team</h1>
            <a 
              href={teamMembers[0].linkedin} 
              target="_blank" 
              rel="noopener noreferrer" 
              className='text-blue-600 hover:text-blue-800 mt-2'
            >
        <div className='flex flex-col items-center py-2 gap-1.5'>
          <div className='h-52 w-52'>
            {loading && <SkeletonImage height={'100%'} width={'100%'} />} {/* Show skeleton while loading */}
            <img 
              src={teamMembers[0].image} 
              onLoad={() => setLoading(false)} 
              alt={teamMembers[0].name} 
              className={`rounded-full h-full w-full object-cover object-top ${loading ? 'hidden' : 'block'}`} 
            />
          </div>
          <h3 className='text-xl font-semibold'>{teamMembers[0].name}</h3> {/* Display first team member's name */}
          <div className='text-sm text-gray-400'>{teamMembers[0].designation}</div> {/* Display designation */}
           
        </div>
          </a> 
        <div className='flex gap-8 flex-wrap justify-center items-center'>
          {teamMembers.map((member, idx) => (
            idx !== 0 && ( // Skip the first member since it's already displayed
               <a 
                  href={member.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className='text-blue-600 hover:text-blue-800 mt-2'
                >
              <div className='flex flex-col items-center gap-1.5 p-8 mx-4' key={member.name}> {/* Added key prop for list */}
                <div className='h-44 w-44'>
                  {loading && <SkeletonImage height={'100%'} width={'100%'} />} {/* Show skeleton while loading */}
                  <img 
                    src={member.image} 
                    onLoad={() => setLoading(false)} 
                    alt={member.name} 
                    className={`rounded-full h-full w-full object-cover object-top ${loading ? 'hidden' : 'block'}`} 
                  />
                </div>
                <h3 className='text-xl font-semibold'>{member.name}</h3> {/* Display member name */}
                <div className='text-sm text-gray-400'>{member.designation}</div> {/* Display designation */}
               

              </div>
                </a>
            )
          ))}
        </div>
      </div>
    </>
  );
}

export default Team;
